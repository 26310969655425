// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../stylesheets/application.scss';
import '../controllers';
import '@fortawesome/fontawesome-free/js/all.js';
import '@fortawesome/fontawesome-free/css/all.css';

import * as Rails from '@rails/ujs';
import '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';

import 'flatpickr/dist/l10n/ja.js';

Rails.start();
ActiveStorage.start();

document.addEventListener('turbo:load', function () {
  // DO NOTHING
});

document.addEventListener('turbo:before-cache', function () {
  // DO NOTHING
});
